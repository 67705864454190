// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("D:\\users\\jschlechte\\workspaces\\github.com\\jschlechte\\mission-control-manual\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bookmarks-js": () => import("D:\\users\\jschlechte\\workspaces\\github.com\\jschlechte\\mission-control-manual\\src\\pages\\bookmarks.js" /* webpackChunkName: "component---src-pages-bookmarks-js" */),
  "component---src-pages-browse-controls-js": () => import("D:\\users\\jschlechte\\workspaces\\github.com\\jschlechte\\mission-control-manual\\src\\pages\\browse-controls.js" /* webpackChunkName: "component---src-pages-browse-controls-js" */),
  "component---src-pages-browse-machines-js": () => import("D:\\users\\jschlechte\\workspaces\\github.com\\jschlechte\\mission-control-manual\\src\\pages\\browse-machines.js" /* webpackChunkName: "component---src-pages-browse-machines-js" */),
  "component---src-pages-browse-parts-js": () => import("D:\\users\\jschlechte\\workspaces\\github.com\\jschlechte\\mission-control-manual\\src\\pages\\browse-parts.js" /* webpackChunkName: "component---src-pages-browse-parts-js" */),
  "component---src-pages-index-js": () => import("D:\\users\\jschlechte\\workspaces\\github.com\\jschlechte\\mission-control-manual\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-view-control-js": () => import("D:\\users\\jschlechte\\workspaces\\github.com\\jschlechte\\mission-control-manual\\src\\pages\\view-control.js" /* webpackChunkName: "component---src-pages-view-control-js" */),
  "component---src-pages-view-machine-js": () => import("D:\\users\\jschlechte\\workspaces\\github.com\\jschlechte\\mission-control-manual\\src\\pages\\view-machine.js" /* webpackChunkName: "component---src-pages-view-machine-js" */),
  "component---src-pages-view-part-js": () => import("D:\\users\\jschlechte\\workspaces\\github.com\\jschlechte\\mission-control-manual\\src\\pages\\view-part.js" /* webpackChunkName: "component---src-pages-view-part-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "D:\\users\\jschlechte\\workspaces\\github.com\\jschlechte\\mission-control-manual\\.cache\\data.json")

